<template>
  <v-app>
    <v-app-bar app color="white" height="70">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon>

      <v-toolbar-title> Michele's Esthetics </v-toolbar-title>
      <v-spacer />
      <v-btn text class="d-none d-sm-flex" @click="$vuetify.goTo('#services')"
        >Services</v-btn
      >
      <v-btn text class="d-none d-sm-flex" @click="$vuetify.goTo('#about')"
        >About</v-btn
      >
      <v-btn text class="d-none d-sm-flex" @click="$vuetify.goTo('#contact')"
        >Contact</v-btn
      >
    </v-app-bar>
    <v-navigation-drawer v-if="$vuetify.breakpoint.mobile" v-model="drawer" app>
      <v-list nav dense>
        <v-list-item
          @click="
            $vuetify.goTo('#measures');
            drawer = false;
          "
        >
          <v-list-item-title class="amber--text">COVID-19</v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="
            $vuetify.goTo('#services');
            drawer = false;
          "
        >
          <v-list-item-title>Services</v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="
            $vuetify.goTo('#about');
            drawer = false;
          "
        >
          <v-list-item-title>About</v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="
            $vuetify.goTo('#contact');
            drawer = false;
          "
        >
          <v-list-item-title>Contact</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-content>
      <section
        id="hero"
        :style="`background: url(${require('@/assets/egypt.jpg')})`"
      >
        <v-row align="center" class="white--text mx-auto py-5" justify="center">
          <v-col
            style="background: rgba(0, 0, 0, 0.7)"
            class="white--text text-center"
            cols="6"
            tag="h1"
          >
            <span
              :class="[
                $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2',
              ]"
              class="font-weight-thin"
            >
              Serving Ottawa Since
            </span>

            <br />

            <span
              :class="[
                $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
              ]"
              class="font-weight-black"
            >
              1997
            </span>
          </v-col>
        </v-row>
      </section>

      <div class="py-12"></div>

      <section id="services">
        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">SERVICES</h2>

          <v-responsive class="mx-auto mb-6" width="56">
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h2 class="text-left">Sugaring - Women</h2>
                </v-card-title>
                <p class="mx-4 text-left">
                  <strong>Alexandria Professional Body Sugaring</strong> is a
                  type of hair removal with a unique blend of natural
                  ingredients with no chemical additives. This type of hair
                  removal uses products from the ancient Middle Egyptians. Body
                  sugaring is gentler, safer and can be used on all types of
                  skin and hair textures. The Alexandria Body Sugaring products
                  do not contain any resins, or preservatives. The product is
                  made of sugar, lemon and water. Alexandria Professional Body
                  Sugaring TM was founded in 1989. The company is based in North
                  America located in Ontario/Canada.
                </p>
                <v-simple-table class="text-left">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Approx Time in Minutes</th>
                        <th>Prices</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in womenSugaringServices" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.time }}</td>
                        <td>{{ item.price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h2 class="text-left">Sugaring - Men</h2>
                </v-card-title>
                <v-simple-table class="text-left">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Approx Time in Minutes</th>
                        <th>Prices</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in menSugaringServices" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.time }}</td>
                        <td>{{ item.price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h2>Nails</h2>
                </v-card-title>
                <v-simple-table class="text-left">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Approx Time in Minutes</th>
                        <th>Prices</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in nailServices" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.time }}</td>
                        <td>{{ item.price }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <div class="py-12"></div>

      <section id="about">
        <v-container class="text-center pb-0">
          <h2 class="display-2 font-weight-bold mb-3">ABOUT US</h2>

          <v-responsive class="mx-auto mb-12" width="56">
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-avatar class="elevation-12 mb-6" size="128">
            <v-img src="@/assets/michele.jpg"></v-img>
          </v-avatar>

          <v-responsive
            class="mx-auto title font-weight-light mb-8"
            max-width="720"
          >
            Michele has been trained as an Alexandria Practitioner since 1997.
            Since then, I have taken several updated courses from Alexandria
            Professional Body Sugaring TM Educators learning better techniques
            and more about their new products. I am a Graduate of Gina's School
            of Esthetics and Electrolysis where I was trained and educated in
            manicures and pedicures. I graduated from Belmonda Beauty Academy in
            Montreal learning the art of giving a professional facial. I am able
            to give the applicable facial depending on your needs and skin type.
          </v-responsive>
          <div></div>
        </v-container>
      </section>

      <div class="py-12"></div>

      <v-sheet id="contact">
        <v-container>
          <h2
            class="display-2 font-weight-bold mb-3 text-uppercase text-center"
          >
            Contact
          </h2>

          <v-responsive class="mx-auto mb-6" width="56">
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>
          <v-row>
            <v-col cols="12" md="6">
              <h3>Hours</h3>
              <p>9 AM to 9PM Monday to Saturday by appointment only.</p>
            </v-col>
            <v-col cols="12" md="6">
              <h3>Phone</h3>
              <p><a href="tel:613-297-8568">613-297-8568</a></p>
            </v-col>
          </v-row>
          <h3 id="email">Email</h3>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="form.name"
              label="Name*"
              :rules="nameRules"
            ></v-text-field>

            <v-text-field
              v-model="form.email"
              label="Email*"
              :rules="emailRules"
            ></v-text-field>

            <v-text-field
              v-model="form.subject"
              label="Subject*"
              :rules="subjectRules"
            ></v-text-field>
            <v-textarea
              v-model="form.message"
              label="Message*"
              :rules="messageRules"
            ></v-textarea>

            <div class="text-center mt-3">
              <v-btn
                color="primary"
                x-large
                @click="sendEmail"
                :disabled="loading"
              >
                <span v-if="!loading">Submit</span>
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-btn>
            </div>
            <v-snackbar right v-model="snackbar" color="amber darken-3">
              Email has been sent.
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  icon
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </v-form>
        </v-container>

        <div class="py-6"></div>
      </v-sheet>
    </v-content>

    <v-footer class="justify-center" color="#292929" height="100">
      <div
        class="title font-weight-light grey--text text--lighten-1 text-center"
      >
        &copy; {{ new Date().getFullYear() }} — Made with 💜 by Justin
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    drawer: false,
    snackbar: false,
    loading: false,
    form: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    valid: true,
    nameRules: [(v) => !!v || "Name is required"],
    subjectRules: [(v) => !!v || "Subject is required"],
    messageRules: [(v) => !!v || "Message is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    womenSugaringServices: [
      {
        name: "Eyebrows",
        time: "15",
        price: "$20.00",
      },
      {
        name: "Upper Lip",
        time: "15",
        price: "$18.00",
      },
      {
        name: "Upper Lip and Chin",
        time: "25",
        price: "$20.00",
      },
      {
        name: "Full Face",
        time: "30",
        price: "$40.00",
      },
      {
        name: "Underarms",
        time: "15",
        price: "$20.00",
      },
      {
        name: "Fingers to Elbows",
        time: "25",
        price: "$30.00",
      },
      {
        name: "Full Arms",
        time: "40",
        price: "$40.00",
      },
      {
        name: "Upper Leg",
        time: "15",
        price: "$52.00",
      },
      {
        name: "Lower Leg",
        time: "30",
        price: "$40.00",
      },
      {
        name: "Full Leg",
        time: "60",
        price: "$70.00",
      },
      {
        name: "Bikini",
        time: "20",
        price: "$35.00",
      },
      {
        name: "Brazilian",
        time: "30",
        price: "$45.00",
      },
      {
        name: "Brazilian with Mud",
        time: "30",
        price: "$50.00",
      },
      {
        name: "Buttocks",
        time: "30",
        price: "$20.00",
      },
      {
        name: "Toes",
        time: "10",
        price: "$12.00",
      },
      {
        name: "Tummy",
        time: "20",
        price: "$20.00",
      },
      {
        name: "Lower Back",
        time: "20",
        price: "$22.00",
      }
    ],
    menSugaringServices: [
      {
        name: "Nose",
        time: "10",
        price: "$12.00",
      },
      {
        name: "Eyebrows",
        time: "18",
        price: "$20.00",
      },
      {
        name: "Ears",
        time: "15",
        price: "$18.00",
      },
      {
        name: "Back",
        time: "45",
        price: "$50.00",
      },
      {
        name: "Full Back and Shoulders",
        time: "55",
        price: "$62.00",
      },
      {
        name: "Chest",
        time: "45",
        price: "$50.00",
      },
      {
        name: "Chest & Shoulders",
        time: "55",
        price: "$62.00",
      },
      {
        name: "Manscape",
        time: "60",
        price: "$58.00",
      },
      {
        name: "Manscape with Mud",
        time: "60",
        price: "$70.00",
      }
    ],
    nailServices: [
      {
        name: "Gel Nails",
        time: "45",
        price: "$45.00",
      },
      {
        name: "Manicure",
        time: "60",
        price: "$35.00",
      },
      {
        name: "Pedicure",
        time: "90",
        price: "$55.00",
      },
    ],
    massageServices: [
      {
        name: "Massage",
        time: "60",
        price: "$100.00 inc. taxes. Receipt provided on request.",
      },
    ],
    facialServices: [
      {
        name: "Facial",
        time: "90",
        price: "$55.00",
      },
    ],
  }),
  methods: {
    setConsent() {
      this.form.subject = "Michele Esthetics Pre-Appointment Screening";
      this.form.message =
        "I confirm that the following statements are all true at the time this email is sent:" +
        "\n\nI currently have no fever or chills, no difficulty breathing, no chest pain, no new or worsening sore throat or cough, no loss of sensation of taste or smell, or no new body aches or pains" +
        "\n\nI have not had contact with someone who has tested positive for COVID-19 or has experienced symptoms in (1) or has returned to Canada from abroad within the past 14 days" +
        "\n\nI have not travelled outside of Canada within the past 14 days" +
        "\n\nI have been practicing physical distancing with people other than those living at my residence or as per the updated recommendations of the Ontario Ministry of Health";
      this.$vuetify.goTo("#email");
    },
    async sendEmail() {
      await this.$refs.form.validate();
      if (this.valid) {
        var data = new FormData();
        data.append("name", this.form.name);
        data.append("email", this.form.email);
        data.append("subject", this.form.subject);
        data.append("message", this.form.message);
        this.loading = true;
        await this.$axios.post('https://formspree.io/f/mleopykg', data);
        this.loading = false;
        this.snackbar = true;
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style scoped>
.v-toolbar__title {
  font-family: "Dancing Script", cursive !important;
  font-size: 32px;
}
</style>
